import Vue from 'vue'
import Vuex from 'vuex'

import validators from './modules/validators'

import configuration from './modules/configuration'
import gui from './modules/gui'
import profile from './modules/profile'

import changetracking from './modules/change-tracking'
import services from './modules/services'

import users from './modules/users'
import useraddresses from './modules/user-addresses'
import userlogins from './modules/user-logins'
import userservices from './modules/user-services'
import userlocations from './modules/user-locations'
import projects from './modules/projects'
import projectaccountingtasks from './modules/project-accounting-tasks'
import accountingtasks from './modules/accountingtasks'
import worklogs from './modules/worklogs'
import clients from './modules/clients'
import contacts from './modules/contacts'

import orders from './modules/orders'
import products from './modules/products'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    configuration,
    gui,
    profile,
    validators,

    changetracking,
    services,

    clients,
    contacts,

    users,
    useraddresses,
    userlogins,
    userservices,
    userlocations,

    projects,
    projectaccountingtasks,
    worklogs,
    accountingtasks,

    orders,

    products
  }
})
