import { USER_ROLES } from '@/constants'

export default [
  {
    path: process.env.VUE_APP_USE_LANG_IN_URL === 'true' ? '/:lang([a-zA-Z][a-zA-Z])?' : '',
    component: { render: h => h('router-view') },
    navigationRoutesEntry: true,
    children: [
      {
        path: '',
        redirect: { name: 'Dashboard' }
      },
      { /* Dashboard */
        name: 'Dashboard',
        path: 'dashboard',
        component: () => import(/* webpackChunkName: "v-dashboard" */ '@/views/Dashboard'),
        meta: {
          auth: [USER_ROLES.admin, USER_ROLES.super],
          navi: {
            arrangement: 'start',
            icon: 'dashboard',
            exact: true
          }
        }
      },
      { /* Locations */
        name: 'Clients',
        path: 'clients',
        component: () => import(/* webpackChunkName: "v-locations" */ '@/views/Clients'),
        meta: {
          auth: [USER_ROLES.admin, USER_ROLES.super],
          navi: {
            arrangement: 'start',
            icon: 'building'
          }
        },
        children: [
          { /* Clients.Add */
            name: 'Clients.Add',
            path: 'add',
            component: () => import(/* webpackChunkName: "v-users-add" */ '@/views/Clients/Add')
          },
          { /* Clients.DefaultRedirect */
            path: ':clientid',
            redirect: { name: 'Clients.Detail' }
          },
          { /* Clients.Detail */
            name: 'Clients.Detail',
            path: ':clientid/detail',
            component: () => import(/* webpackChunkName: "v-locations-detail" */ '@/views/Clients/Detail'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'location'
              }
            }
          },
          { /* Clients.Notes */
            name: 'Clients.Notes',
            path: ':clientid/notes',
            component: () => import(/* webpackChunkName: "v-locations-notes" */ '@/views/Clients/Notes'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'notes'
              }
            }
          },
          { /* Clients.ChangeTracking */
            name: 'Clients.ChangeTracking',
            path: ':clientid/changetracking',
            component: () => import(/* webpackChunkName: "v-locations-changetracking" */ '@/views/Clients/ChangeTracking'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'history'
              }
            }
          }
        ]
      },
      { /* Locations */
        name: 'Contacts',
        path: 'contacts',
        component: () => import(/* webpackChunkName: "v-locations" */ '@/views/Contacts'),
        meta: {
          auth: [USER_ROLES.admin, USER_ROLES.super],
          navi: {
            arrangement: 'start',
            icon: 'person-vcard'
          }
        },
        children: [
          { /* Clients.Add */
            name: 'Contacts.Add',
            path: 'add',
            component: () => import(/* webpackChunkName: "v-users-add" */ '@/views/Contacts/Add')
          },
          { /* Clients.DefaultRedirect */
            path: ':contactid',
            redirect: { name: 'Contacts.Detail' }
          },
          { /* Clients.Detail */
            name: 'Contacts.Detail',
            path: ':contactid/detail',
            component: () => import(/* webpackChunkName: "v-locations-detail" */ '@/views/Contacts/Detail'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'user'
              }
            }
          },
          { /* Clients.Notes */
            name: 'Contacts.Notes',
            path: ':contactid/notes',
            component: () => import(/* webpackChunkName: "v-locations-notes" */ '@/views/Contacts/Notes'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'notes'
              }
            }
          },
          { /* Clients.ChangeTracking */
            name: 'Contacts.ChangeTracking',
            path: ':contactid/changetracking',
            component: () => import(/* webpackChunkName: "v-locations-changetracking" */ '@/views/Contacts/ChangeTracking'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'history'
              }
            }
          }
        ]
      },
      { /* Users */
        name: 'Users',
        path: 'users',
        component: () => import(/* webpackChunkName: "v-users" */ '@/views/Users'),
        meta: {
          auth: [USER_ROLES.admin, USER_ROLES.super],
          navi: {
            arrangement: 'start',
            icon: 'users'
          }
        },
        children: [
          { /* Users.Add */
            name: 'Users.Add',
            path: 'add',
            component: () => import(/* webpackChunkName: "v-users-add" */ '@/views/Users/Add')
          },
          { /* Users.DefaultRedirect */
            path: ':userid',
            redirect: { name: 'Users.Detail' }
          },
          { /* Users.Detail */
            name: 'Users.Detail',
            path: ':userid/detail',
            component: () => import(/* webpackChunkName: "v-users-detail" */ '@/views/Users/Detail'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'user'
              }
            }
          },
          { /* Users.Detail */
            name: 'Users.ClickupAbsenceCheck',
            path: ':userid/clickupcheck',
            component: () => import(/* webpackChunkName: "v-users-detail" */ '@/views/Users/ClickupAbsenceCheck'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'smartwatch'
              }
            }
          },
          { /* Users.Logins */
            name: 'Users.Logins',
            path: ':userid/logins',
            component: () => import(/* webpackChunkName: "v-users-logins" */ '@/views/Users/Logins'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'accounts'
              }
            }
          },
          { /* Users.Notes */
            name: 'Users.Notes',
            path: ':userid/notes',
            component: () => import(/* webpackChunkName: "v-users-notes" */ '@/views/Users/Notes'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'notes'
              }
            }
          },
          { /* Users.ChangeTracking */
            name: 'Users.ChangeTracking',
            path: ':userid/changetracking',
            component: () => import(/* webpackChunkName: "v-users-changetracking" */ '@/views/Users/ChangeTracking'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'history'
              }
            }
          }
        ]
      },
      { /* Projects */
        name: 'Projects',
        path: 'projects',
        component: () => import(/* webpackChunkName: "v-orders" */ '@/views/Projects'),
        meta: {
          auth: [USER_ROLES.admin, USER_ROLES.super],
          navi: {
            arrangement: 'start',
            icon: 'orders'
          }
        },
        children: [
          {
            name: 'Projects.Add',
            path: 'add',
            component: () => import(/* webpackChunkName: "v-users-add" */ '@/views/Projects/Add')
          },
          {
            path: ':projectid',
            redirect: { name: 'Projects.Detail' }
          },
          {
            name: 'Projects.Detail',
            path: ':projectid/detail',
            component: () => import(/* webpackChunkName: "v-orders-detail" */ '@/views/Projects/Detail'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'orders'
              }
            }
          },
          {
            name: 'Projects.Worklogs',
            path: ':projectid/worklogs',
            component: () => import(/* webpackChunkName: "v-locations-addresses" */ '@/views/Projects/Worklogs'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'addresses'
              }
            }
          },
          { /* Projects.AccountingTasks */
            name: 'Projects.Worklogs.ReadyToPrepare',
            path: ':projectid/worklogstoprepare',
            component: () => import(/* webpackChunkName: "v-locations-addresses" */ '@/views/Projects/Worklogs/ReadyToPrepare'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'currency-dollar'
              }
            }
          },
          { /* Projects.AccountingTasks */
            name: 'Projects.Worklogs.SentToBexio',
            path: ':projectid/senttobexio',
            component: () => import(/* webpackChunkName: "v-locations-addresses" */ '@/views/Projects/Worklogs/SentToBexio'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'send'
              }
            }
          },
          { /* Projects.AccountingTasks */
            name: 'Projects.AllWorklogs',
            path: ':projectid/allworklogs',
            component: () => import(/* webpackChunkName: "v-locations-addresses" */ '@/views/Projects/Worklogs/AllWorklogs'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'smartwatch'
              }
            },
            children: [
              { /* Locations.Addresses.Detail */
                name: 'Projects.Worklogs.Detail',
                path: ':worklogid/detail',
                component: () => import(/* webpackChunkName: "v-locations-addresses-detail" */ '@/views/Projects/Worklogs/Detail'),
                meta: {
                  navi: {
                    arrangement: 'start',
                    icon: 'addresses'
                  }
                }
              },
              { /* Locations.Addresses.Detail */
                name: 'Projects.Worklogs.Add',
                path: 'add',
                component: () => import(/* webpackChunkName: "v-users-add" */ '@/views/Projects/Worklogs/Add')
              }
            ]
          },
          { /* Projects.AccountingTasks */
            name: 'Projects.Worklogs.ClickUpAssignementNeeded',
            path: ':projectid/worklogswithclickupunassigned',
            component: () => import(/* webpackChunkName: "v-locations-addresses" */ '@/views/Projects/Worklogs/ClickUpAssignementNeeded'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'card-checklist'
              }
            }
          },
          { /* Projects.AccountingTasks */
            name: 'Projects.AccountingTasks',
            path: ':projectid/accountingtasks',
            component: () => import(/* webpackChunkName: "v-locations-addresses" */ '@/views/Projects/AccountingTasks'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'piggy-bank'
              }
            },
            children: [
              { /* Locations.Addresses.Detail */
                name: 'Projects.AccountingTasks.Detail',
                path: ':projectaccountingtaskid/detail',
                component: () => import(/* webpackChunkName: "v-locations-addresses-detail" */ '@/views/Projects/AccountingTasks/Detail'),
                meta: {
                  navi: {
                    arrangement: 'start',
                    icon: 'addresses'
                  }
                }
              }
            ]
          },
          { /* Projects.AccountingTasks */
            name: 'Projects.Worklogs.Ignored',
            path: ':projectid/ignored',
            component: () => import(/* webpackChunkName: "v-locations-addresses" */ '@/views/Projects/Worklogs/Ignored'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'archive-fill'
              }
            }
          },
          { /* Projects.Detail */
            name: 'Projects.Invoices',
            path: ':projectid/invoices',
            component: () => import(/* webpackChunkName: "v-orders-detail" */ '@/views/Projects/Invoices'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'receipt'
              }
            }
          },
          { /* Clients.Notes */
            name: 'Projects.Notes',
            path: ':projectid/notes',
            component: () => import(/* webpackChunkName: "v-locations-notes" */ '@/views/Projects/Notes'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'notes'
              }
            }
          },
          { /* Orders.ChangeTracking */
            name: 'Projects.ChangeTracking',
            path: ':projectid/changetracking',
            component: () => import(/* webpackChunkName: "v-orders-changetracking" */ '@/views/Projects/ChangeTracking'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'history'
              }
            }
          },
          {
            name: 'Projects.Operations',
            path: ':projectid/operations',
            component: () => import(/* webpackChunkName: "v-locations-notes" */ '@/views/Projects/Operations'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'arrow-right-square'
              }
            }
          }
        ]
      },
      { /* Login */
        name: 'Login',
        path: 'auth/login',
        component: () => import(/* webpackChunkName: "v-auth-login" */ '@/views/auth/Login')
      },
      { /* Logout */
        name: 'Logout',
        path: 'auth/logout',
        component: () => import(/* webpackChunkName: "v-auth-logout" */ '@/views/auth/Logout')
      },
      { /* Forbidden (403) */
        name: 'Forbidden',
        path: 'auth/403',
        component: () => import(/* webpackChunkName: "v-auth-forbidden" */ '@/views/auth/Forbidden')
      },
      { /* Notfound (404) */
        name: 'Notfound',
        path: '404',
        component: () => import(/* webpackChunkName: "v-notfound" */ '@/views/Notfound')
      },
      {
        name: 'Refresh',
        path: 'refresh',
        component: {
          render: h => h('div'),
          beforeRouteEnter (to, from, next) {
            next(vm => {
              vm.$router.replace(from.path)
            })
          }
        }
      },
      {
        path: '*',
        redirect: { name: 'Notfound' }
      }
    ]
  }
]
