<template>
<div>
<transition name="slide">
  <nav v-if="isNavigationVisible" class="navigation-main">
    <div v-if="showMainlevel" class="navigation-level bg-variant-dark">
      <div class="level-routes">
        <ul>
          <li v-for="route in mainlevel.start" :key="`route-start-${route.name}`">
            <navigation-link class="level-item" :route="route"/>
          </li>
        </ul>

        <ul>
          <li v-for="route in mainlevel.end" :key="`route-end-${route.name}`">
            <navigation-link class="level-item" :route="route"/>
          </li>
        </ul>
      </div>

      <div class="level-meta">
        <ul>
          <li v-if="!hideSingleLanguage || availableLanguages.length >= 1">
            <b-dropdown v-if="availableLanguages.length > 0" class="level-item" variant="transparent" toggle-tag="a" block dropup no-flip>
              <template #button-content>
                <b-icon icon="globe" height="21"/> {{ $t(`languages.${$i18n.locale}.name`) }}
              </template>

              <b-dropdown-item v-for="language in availableLanguages" :key="language" @click="setLanguage(language)">{{ $t(`languages.${language}.name`) }}</b-dropdown-item>
            </b-dropdown>

            <div v-else class="level-item">
              <b-icon icon="globe"/> {{ $t(`languages.${$i18n.locale}.name`) }}
            </div>
          </li>

          <li v-if="showLogout">
            <b-link class="level-item" @click="logout">
              <b-icon icon="logout"/> {{ $t('layout.navigation.meta.logout') }}
            </b-link>
          </li>
        </ul>
      </div>
    </div>

        <div v-if="showSublevel" class="navigation-level bg-variant-light">
          <div v-if="searchEntityKey" class="level-filters">
            <div class="level-title">
              <b-form-row class="justify-content-between align-items-center">
                <b-col cols="auto">
                  {{ $t('components.navigation.filters.title') }}
                </b-col>

                <b-col cols="auto">
                  <b-button variant="link" class="p-0" @click="resetSearchFilters">{{ $t('components.navigation.filters.button.resetall') }}</b-button>
                </b-col>
              </b-form-row>
            </div>

            <ul>
              <li v-for="filter in searchFilters" :key="filter.field">
                <component
                  :is="filter.component"
                  v-bind:filter="filter"
                  @filter:change="updateSearchFilters"
                />
              </li>
            </ul>
          </div>

          <div v-else class="level-routes">
            <ul>
              <li v-if="currentRoute.hasBacklink">
                <b-link class="level-item level-item-backlink navigation-link" :to="currentRoute.backlink" exact><b-icon icon="arrow-left"/>{{ $t('components.navigation.backlink.label') }}</b-link>
              </li>

              <template v-if="sublevel">
                <li v-for="route in sublevel.start" :key="`route-start-${route.name}`">
                  <navigation-link class="level-item" :route="route"/>
                </li>
              </template>
            </ul>

            <ul v-if="sublevel">
              <li v-for="route in sublevel.end" :key="`route-end-${route.name}`">
                <navigation-link class="level-item" :route="route"/>
              </li>
            </ul>
          </div>
        </div>
  </nav>
  </transition>

    <b-button variant="warning" class="toggle-navigation-btn primary" @click="toggleNavigation">
      <span v-if="isNavigationVisible">Navigation ausblenden</span>
      <span v-else>Navigation einblenden</span>
  </b-button>
  </div>
</template>

<script>
import { NAVIGATION_ROUTES } from '@/$plugins/router'
import { authManagers } from '@/$plugins/auth'
import NavigationLink from '@/components/layout/NavigationLink'

import FilterText from '@/components/search/FilterText'
import FilterMultiselect from '@/components/search/FilterMultiselect'
import FilterDaterange from '@/components/search/FilterDaterange'
import FilterGeopoint from '@/components/search/FilterGeopoint'
import FilterUnknown from '@/components/search/FilterUnknown'

export default {
  name: 'NavigationMain',
  components: {
    NavigationLink,
    FilterText,
    FilterMultiselect,
    FilterDaterange,
    FilterGeopoint,
    FilterUnknown
  },
  data () {
    return {
      hideSingleLanguage: true,
      isNavigationVisible: true // This controls the visibility
    }
  },
  computed: {
    currentRoute () {
      // const options = (this.$route.meta || {}).navi || {}
      const matchedNames = this.$route.matched.map(m => m.name).filter(m => m !== undefined)
      const parentName = matchedNames[matchedNames.length - 2]

      return {
        id: this.$route.fullPath,
        name: this.$route.name,
        matchedNames,
        backlink: parentName ? { name: parentName } : null,
        hasBacklink: parentName !== undefined
      }
    },
    routes () {
      return NAVIGATION_ROUTES
        .reduce(function createLevels (routes, route) {
          const hasChildren = (route.children || []).length > 0
          let sublevel

          if (hasChildren) sublevel = (route.children || []).reduce(createLevels.bind(this), { start: [], end: [] })

          if (route.meta.navi.arrangement) {
            routes[route.meta.navi.arrangement].push({
              name: route.name,
              path: route.path,
              meta: route.meta,
              isActive: this.currentRoute.matchedNames.includes(route.name),
              isExactActive: route.name === this.currentRoute.name,
              hasSublevel: sublevel !== undefined,
              sublevel
            })
          }

          return routes
        }.bind(this), { start: [], end: [] })
    },
    levels () {
      return [this.routes]
        .reduce(function createActiveLevels (levels, level) {
          const activeRoute = [].concat(level.start, level.end).find(route => route.isActive) || null

          if (activeRoute !== null) {
            levels.push(level)
            if (activeRoute.sublevel) [activeRoute.sublevel].reduce(createActiveLevels, levels)
          }

          return levels
        }, [])
    },
    mainlevel () {
      return this.levels[0] || null
    },
    sublevel () {
      const sublevels = this.levels.slice(1)
      const sublevel = sublevels[sublevels.length - 1] || { start: [], end: [] }

      return [].concat(sublevel.start, sublevel.end).find(r => r.isExactActive) ? sublevel : null
    },
    searchEntityKey () {
      return this.$store.getters['gui/getSearchEntityKey']
    },
    searchFilters () {
      return this.searchEntityKey ? this.$store.getters[`${this.searchEntityKey}/filters`] : []
    },
    availableLanguages () {
      return this.$store.getters['gui/getLanguagesAvailable'].exact.filter(l => l !== this.$i18n.locale)
    },
    showMainlevel () {
      return this.mainlevel !== null
    },
    showSublevel () {
      return this.searchEntityKey || this.sublevel !== null || this.currentRoute.backlink
    },
    showLogout () {
      return this.$auth.check()
    }
  },
  methods: {
    setLanguage (lang) {
      if (this.$auth.check()) {
        this.$store.dispatch('gui/setLanguage', lang)
      } else {
        this.$store.commit('gui/setLanguage', lang)
      }
    },
    updateSearchFilters (filter) {
      this.$store.dispatch(`${this.searchEntityKey}/setFilters`)
    },
    resetSearchFilters () {
      this.$store.dispatch(`${this.searchEntityKey}/resetFilters`)
    },
    logout () {
      authManagers.logout()
    },
    toggleNavigation () {
      this.isNavigationVisible = !this.isNavigationVisible
      console.log('isNavigationVisible:', this.isNavigationVisible) // Check if this logs correctly
    }
  }
}
</script>

<style lang="scss">
$navigation-level-padding-y: 0 !default;
$navigation-level-padding-x: 0 !default;
$navigation-level-min-width: 160px !default;
$navigation-level-max-width: 260px !default;
$navigation-level-bg-light: $gray-200 !default;
$navigation-level-bg-dark: darken($gray-200, 3%) !default;

$navigation-sublevels-width: $navigation-level-max-width !default;

$navigation-level-filters-y-gap: $spacer * 0.6 !default;

$navigation-level-list-y-gap: $navigation-level-filters-y-gap !default;
$navigation-level-list-to-list-gap: $spacer !default;

$navigation-level-sub-gap: $spacer !default;
$navigation-level-sub-font-size: $font-size-sm !default;

$navigation-level-title-bottom-gap: $spacer * 0.5 !default;
$navigation-level-title-y-gap: $spacer * 0.2 !default;
$navigation-level-title-x-gap: $spacer * 1.5 !default;
$navigation-level-title-bg: transparent !default;
$navigation-level-title-border-top: 0 none !default;
$navigation-level-title-border-bottom: 2px solid rgba($black, 0.1) !default;
$navigation-level-title-font-family: $font-family-base !default;
$navigation-level-title-font-size: $font-size-base !default;
$navigation-level-title-font-weight: $font-weight-bold !default;
$navigation-level-title-line-height: $line-height-base !default;

$navigation-level-item-y-gap: $spacer * 0.4 !default;
$navigation-level-item-x-gap: $spacer * 1.5 !default;
$navigation-level-item-font-family: $font-family-base !default;
$navigation-level-item-font-size: $font-size-base !default;
$navigation-level-item-font-weight: $font-weight-bold !default;
$navigation-level-item-line-height: 1.1 !default;
$navigation-level-item-icon-gap: $spacer * 0.5 !default;
$navigation-level-item-icon-size: $spacer * 1.25 !default;
$navigation-level-item-icon-color: inherit !default;

$navigation-level-item-backlink-gap: $spacer * 0.4 !default;
$navigation-level-item-backlink-border: $border-width solid $border-color !default;
$navigation-level-item-backlink-font-family: $navigation-level-item-font-family !default;
$navigation-level-item-backlink-font-size: $font-size-sm !default;
$navigation-level-item-backlink-font-weight: $navigation-level-item-font-weight !default;
$navigation-level-item-backlink-line-height: $navigation-level-item-line-height !default;

// overwrite link styles
$navigation-link-padding-y: $navigation-level-item-y-gap;
$navigation-link-padding-x: $navigation-level-item-x-gap;
$navigation-link-font-family: $navigation-level-item-font-family;
$navigation-link-font-size: $navigation-level-item-font-size;
$navigation-link-font-weight: $navigation-level-item-font-weight;
$navigation-link-line-height: $navigation-level-item-line-height;
$navigation-link-icon-gap: $navigation-level-item-icon-gap;
$navigation-link-icon-size: $navigation-level-item-icon-size;
$navigation-link-icon-color: $navigation-level-item-icon-color;

.navigation-main {
  display: flex;
  height: 100%;

  ul {
    @include list-unstyled();
  }

  .navigation-level {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    overflow-y: auto;
    padding: $navigation-level-padding-y $navigation-level-padding-x;
    min-width: $navigation-level-min-width;
    max-width: $navigation-level-max-width;
    height: 100%;

    .level-filters {
      padding-top: $navigation-level-filters-y-gap;
      padding-bottom: $navigation-level-filters-y-gap;

      > ul {
        margin: 0;
      }
    }

    .level-routes {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 0 1 100%;

      > ul {
        margin: 0;

        &:first-child {
          padding-top: $navigation-level-list-y-gap;
        }

        &:last-child {
          padding-bottom: $navigation-level-list-y-gap;
        }

        ~ ul {
          margin-top: $navigation-level-list-to-list-gap;
        }
      }
    }

    .level-meta {
      border-top: 1px solid $border-color;

      > ul {
        margin: 0;
        padding-top: $navigation-level-list-y-gap;
        padding-bottom: $navigation-level-list-y-gap;
      }
    }

    .level-filters,
    .level-routes,
    .level-meta {
      .level-title {
        margin: 0 0 $navigation-level-title-bottom-gap;
        padding: $navigation-level-title-y-gap $navigation-level-title-x-gap;
        background-color: $navigation-level-title-bg;
        border-top: $navigation-level-title-border-top;
        border-bottom: $navigation-level-title-border-bottom;
        font-family: $navigation-level-title-font-family;
        font-size: $navigation-level-title-font-size;
        font-weight: $navigation-level-title-font-weight;
        line-height: $navigation-level-title-line-height;
      }

      .level-item {
        display: flex;
        align-items: center;
        padding: $navigation-level-item-y-gap $navigation-level-item-x-gap;
        font-family: $navigation-level-item-font-family;
        font-size: $navigation-level-item-font-size;
        font-weight: $navigation-level-item-font-weight;
        line-height: $navigation-level-item-line-height;
        text-align: left;
        text-decoration: none;

        > .bi {
          margin-right: $navigation-level-item-icon-gap;
          font-size: $navigation-level-item-icon-size;
          color: $navigation-level-item-icon-color;
        }

        &.level-item-backlink {
          margin-bottom: $navigation-level-item-backlink-gap;
          border-bottom: $navigation-level-item-backlink-border;
          font-family: $navigation-level-item-backlink-font-family;
          font-size: $navigation-level-item-backlink-font-size;
          font-weight: $navigation-level-item-backlink-font-weight;
          line-height: $navigation-level-item-backlink-line-height;

          > .bi {
            margin-right: $navigation-level-item-icon-gap * 0.5;
          }
        }
      }
    }

    &.bg-variant-light { background-color: $navigation-level-bg-light; }
    &.bg-variant-dark { background-color: $navigation-level-bg-dark; }
  }
}

/* Add transition styles */
.slide-enter-active, .slide-leave-active {
  transition: transform 0.3s ease;
}
.slide-enter-from, .slide-leave-to {
  transform: translateX(-100%);
}
.slide-enter-to, .slide-leave-from {
  transform: translateX(0%);
}

/* Style for the toggle button */
.toggle-navigation-btn {
  position: fixed; /* Or absolute, depending on your layout */
  top: 10px; /* Place it at the bottom of the screen */
  left: 250px; /* Adjust as needed */
  z-index: 1050; /* Ensure it's above other content */
}

/* ... other styles ... */
</style>
