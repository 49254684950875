import { STOREMODULE_ENDPOINT_MAP, STOREMODULE_ENDPOINTMODULE_MAP } from '@/constants'

import http from '@/$plugins/http'

import base from '../base'
import IS from '../base/is'
import ENTITY from '../base/entity'

export default {
  namespaced: true,
  state: base.createState(IS.state, ENTITY.state, {
    userRoles: []
  }),
  getters: base.createGetters(IS.getters, ENTITY.getters, {
    getUserRoles: state => state.userRoles
  }),
  mutations: base.createMutations(IS.mutations, ENTITY.mutations, {
    setUserRoles (state, data = []) {
      state.userRoles = data.roles
    }
  }),
  actions: base.createEntityActions(STOREMODULE_ENDPOINTMODULE_MAP.employee, STOREMODULE_ENDPOINT_MAP.userlogins, IS.actions, {
    getDefinition: ENTITY.actions.getDefinition,
    getAll: ENTITY.actions.getAll,
    triggerPasswordreset (serverModuleName = '', serverEntityName = '', entityKey = '') {
      return function ({ state, getters, commit, dispatch }, options = {}) {
        const o = base.getOptions(options)

        commit('setSending', { key: 'triggerPasswordreset', sending: true })

        return new Promise(resolve => {
          http({
            method: 'put',
            url: `/${serverModuleName}/api/${entityKey}${o.by.url.from}/resetpassword`
          })
            .then(response => {
              commit('setEntity', { key: entityKey, entity: response.data.result.value })
              resolve(getters.getEntity(response.data.result.value.id))
            })
            .finally(() => {
              commit('setLoading', { key: 'triggerPasswordreset', loading: false })
            })
        })
      }
    },
    triggerEmailConfirmation (serverModuleName = '', serverEntityName = '', entityKey = '') {
      return function ({ state, getters, commit, dispatch }, options = {}) {
        const o = base.getOptions(options)

        commit('setSending', { key: 'triggerEmailConfirmation', sending: true })

        return new Promise(resolve => {
          http({
            method: 'put',
            url: `/${serverModuleName}/api/${entityKey}${o.by.url.from}/setemailconfirmed`
          })
            .then(response => {
              commit('setEntity', { key: entityKey, entity: response.data.result.value })
              resolve(getters.getEntity(response.data.result.value.id))
            })
            .finally(() => {
              commit('setLoading', { key: 'triggerEmailConfirmation', loading: false })
            })
        })
      }
    },
    triggerResetLockout (serverModuleName = '', serverEntityName = '', entityKey = '') {
      return function ({ state, getters, commit, dispatch }, options = {}) {
        const o = base.getOptions(options)

        commit('setSending', { key: 'triggerResetLockout', sending: true })

        return new Promise(resolve => {
          http({
            method: 'put',
            url: `/${serverModuleName}/api/${entityKey}${o.by.url.from}/resetlockout`
          })
            .then(response => {
              commit('setEntity', { key: entityKey, entity: response.data.result.value })
              resolve(getters.getEntity(response.data.result.value.id))
            })
            .finally(() => {
              commit('setLoading', { key: 'triggerResetLockout', loading: false })
            })
        })
      }
    },
    setApprovalState (serverModuleName = '', serverEntityName = '', entityKey = '') {
      return function ({ state, getters, commit, dispatch }, options = {}) {
        const o = base.getOptions(options)

        commit('setSending', { key: 'setApprovalState', sending: true })

        return new Promise(resolve => {
          http({
            method: 'put',
            url: `/${serverModuleName}/api/${entityKey}${o.by.url.from}/setapproval/${o.data.state}`
          })
            .then(response => {
              commit('setEntity', { key: entityKey, entity: response.data.result.value })
              resolve(getters.getEntity(response.data.result.value.id))
            })
            .finally(() => {
              commit('setLoading', { key: 'setApprovalState', loading: false })
            })
        })
      }
    },
    getAllUserRoles (serverModuleName = '', serverEntityName = '', entityKey = '') {
      return function ({ state, getters, commit, dispatch }, options = {}) {
        const o = base.getOptions(options)

        commit('setSending', { key: 'getAllUserRoles', sending: true })

        return new Promise(resolve => {
          http({
            method: 'get',
            url: `/${serverModuleName}/api/${entityKey}${o.by.url.from}/roles`
          })
            .then(response => {
              commit('setUserRoles', { roles: response.data.result })
              resolve(getters.getUserRoles)
            })
            .finally(() => {
              commit('setLoading', { key: 'getAllUserRoles', loading: false })
            })
        })
      }
    },
    addUserRole (serverModuleName = '', serverEntityName = '', entityKey = '') {
      return function ({ state, getters, commit, dispatch }, options = {}) {
        const o = base.getOptions(options)

        commit('setSending', { key: 'addUserRole', sending: true })

        return new Promise(resolve => {
          http({
            method: 'post',
            url: `/${serverModuleName}/api/${entityKey}${o.by.url.from}/roles/${o.data.roleName}`
          })
            .then(response => {
              commit('setUserRoles', { roles: response.data.result })
              resolve(getters.getUserRoles)
            })
            .finally(() => {
              commit('setLoading', { key: 'addUserRole', loading: false })
            })
        })
      }
    },
    removeUserRole (serverModuleName = '', serverEntityName = '', entityKey = '') {
      return function ({ state, getters, commit, dispatch }, options = {}) {
        const o = base.getOptions(options)

        commit('setSending', { key: 'removeUserRole', sending: true })

        return new Promise(resolve => {
          http({
            method: 'delete',
            url: `/${serverModuleName}/api/${entityKey}${o.by.url.from}/roles/${o.data.roleName}`
          })
            .then(response => {
              commit('setUserRoles', { roles: response.data.result })
              resolve(getters.getUserRoles)
            })
            .finally(() => {
              commit('setLoading', { key: 'removeUserRole', loading: false })
            })
        })
      }
    }
  })
}
