import { STOREMODULE_ENDPOINT_MAP, STOREMODULE_ENDPOINTMODULE_MAP } from '@/constants'

// import http from '@/$plugins/http'

import base from '../base'
import IS from '../base/is'
import CHANGE_REQUESTS from '../base/change-requests'
import NOTES from '../base/entity-notes'
import REQUIRED_ACTIONS from '../base/entity-requiredactions'
import SEARCH from '../base/entity-search'
import MAINENTITY from '../base/entity-main'

export default {
  namespaced: true,
  state: base.createState(IS.state, CHANGE_REQUESTS.state, NOTES.state, REQUIRED_ACTIONS.state, SEARCH.state, MAINENTITY.state, { /* define custom state-properties */ }),
  getters: base.createGetters(IS.getters, CHANGE_REQUESTS.getters, NOTES.getters, REQUIRED_ACTIONS.getters, SEARCH.getters, MAINENTITY.getters, { /* define custom getters */ }),
  mutations: base.createMutations(IS.mutations, CHANGE_REQUESTS.mutations, NOTES.mutations, REQUIRED_ACTIONS.mutations, SEARCH.mutations, MAINENTITY.mutations, { /* define custom mutations */ }),
  actions: base.createEntityActions(STOREMODULE_ENDPOINTMODULE_MAP.business, STOREMODULE_ENDPOINT_MAP.clients, IS.actions, CHANGE_REQUESTS.actions, NOTES.actions, REQUIRED_ACTIONS.actions, SEARCH.actions, MAINENTITY.actions, { /* define custom actions */ })
}
